import { useEffect, useState } from 'react'
import { DateString, Serial } from 'shared/types/utils'
import { Button } from './components/Button'
import { Input } from './components/Input'
import { Select } from './components/Select'
import { Selection } from './Explorer'
import { useS3Context } from './S3Provider'

type Props = {
  setSelection: (selection: Selection) => void
}

export function StandalonePath({ setSelection }: Props) {
  const [availableSerials, setAvailableSerials] = useState<Serial[]>([])
  const [availableRecordsSessions, setAvailableRecordsSessions] = useState<
    string[]
  >([])
  const [availableDates, setAvailableDates] = useState<DateString[]>([])

  const [selectedSerial, setSelectedSerial] = useState<Serial>()
  const [selectedRecordsSession, setSelectedRecordSession] = useState<string>()
  const [selectedDate, setSelectedDate] = useState<string>('1970-01-01')

  const { listSubfolders } = useS3Context()

  useEffect(() => {
    // Load path from URL
    const pathname = window.location.pathname
    const parts = pathname.split('/')
    if (parts.length > 3) {
      setSelectedSerial(parts[1])
      setSelectedRecordSession(parts[2])
      setSelectedDate(parts[3])
      setValidatedState({
        validatedSerial: parts[1],
        validatedRecordsSession: parts[2],
        validatedDate: parts[3],
      })
    }
  }, [])

  // List available serials from bucket
  useEffect(() => {
    listSubfolders('').then((serials) => {
      setAvailableSerials(serials)
    })
  }, [listSubfolders])

  // List available recordsSessions from bucket
  useEffect(() => {
    if (selectedSerial === undefined) return
    listSubfolders(`${selectedSerial}/`).then((recordsSessions) => {
      setAvailableRecordsSessions(recordsSessions)
    })
  }, [listSubfolders, selectedSerial])

  // List available dates from bucket
  useEffect(() => {
    if (selectedSerial === undefined || selectedRecordsSession === undefined)
      return
    listSubfolders(`${selectedSerial}/${selectedRecordsSession}/`).then(
      (dates) => {
        setAvailableDates(dates)
      },
    )
  }, [selectedSerial, selectedRecordsSession, listSubfolders])

  type ValidatedState = {
    validatedSerial: Serial | undefined
    validatedRecordsSession: string | undefined
    validatedDate: DateString | undefined
  }

  const [
    { validatedDate, validatedRecordsSession, validatedSerial },
    setValidatedState,
  ] = useState<ValidatedState>({
    validatedSerial: selectedSerial,
    validatedRecordsSession: selectedRecordsSession,
    validatedDate: selectedDate,
  })

  function minDate() {
    // FIXME role not used correctly in old listen, acces to listen must be reviewed
    if (selectedDate === undefined) return undefined
    return availableDates[0]
  }

  function maxDate() {
    if (selectedDate === undefined) return undefined
    return availableDates[availableDates.length - 1]
  }

  const prevDate = () => {
    if (selectedDate === undefined) return
    const index = availableDates.indexOf(selectedDate)
    if (index > 0) setSelectedDate(availableDates[index - 1])
  }

  const nextDate = () => {
    if (selectedDate === undefined) return
    const index = availableDates.indexOf(selectedDate)
    if (index < availableDates.length - 1)
      setSelectedDate(availableDates[index + 1])
  }

  const validate = () => {
    setValidatedState({
      validatedSerial: selectedSerial,
      validatedRecordsSession: selectedRecordsSession,
      validatedDate: selectedDate,
    })
    // window.history.replaceState(null, '', `/${selectedSerial}/${selectedDate}`)
  }

  useEffect(() => {
    setSelection({
      serial: validatedSerial ?? 'FIXME UNKNOWN SERIAL',
      date: validatedDate ?? 'FIXME UNKNOWN DATE',
      timeZone: 'Europe/Paris', // reasonnable default
      prefix: `${validatedSerial}/${validatedRecordsSession}/${validatedDate}`,
    })
  }, [validatedSerial, validatedDate, validatedRecordsSession, setSelection])

  const isValidatedState =
    selectedSerial === validatedSerial &&
    selectedRecordsSession === validatedRecordsSession &&
    selectedDate === validatedDate

  return (
    <div className="flex flex-row gap-4">
      <Select
        onChange={(event) => {
          setSelectedSerial(event.target.value)
        }}
        value={selectedSerial}
      >
        {availableSerials.map((serial) => (
          <option key={serial} value={serial}>
            {serial}
          </option>
        ))}
      </Select>
      <Select
        onChange={(event) => {
          setSelectedRecordSession(event.target.value)
        }}
        value={selectedRecordsSession}
      >
        {availableRecordsSessions.map((recordsSession) => (
          <option key={recordsSession} value={recordsSession}>
            {recordsSession}
          </option>
        ))}
      </Select>
      <div className="flex gap-2">
        <Button
          onClick={prevDate}
          disabled={
            selectedDate === undefined ||
            availableDates.indexOf(selectedDate) === 0
          }
        >
          &larr;
        </Button>
        <Input
          value={selectedDate}
          type="date"
          min={minDate()}
          max={maxDate()}
          onChange={(e) => setSelectedDate(e.target.value)}
        />
        <Button
          onClick={nextDate}
          disabled={
            selectedDate === undefined ||
            availableDates.indexOf(selectedDate) === availableDates.length - 1
          }
        >
          &rarr;
        </Button>
      </div>
      <Button disabled={isValidatedState} onClick={validate}>
        Valider
      </Button>
    </div>
  )
}
