import { BucketName } from 'common/types'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { useEffect, useMemo, useState } from 'react'
import {
  GetMetricsNamesParams,
  GetMetricsNamesResponseData,
  MetricKey,
} from 'shared/types/victoria'
import {
  MetricsConfig,
  defaultMetrics,
  metricDisplayName,
} from 'shared/utils/web/metrics'
import { BucketDialog } from './BucketDialog'
import { Explorer, Selection } from './Explorer'
import { Path, calculateTimestampRange } from './Path'
import { S3Provider } from './S3Provider'
import { StandalonePath } from './StandalonePath'
import { DropdownCheckboxes } from './components/DropdownCheckboxes'
import { app } from './firebase'

const functions = getFunctions(app, 'europe-west1')

const getMetricsNames = httpsCallable<
  GetMetricsNamesParams,
  GetMetricsNamesResponseData
>(functions, 'getMetricsNames')

const selectedPredictionsStorageKey = 'selectedPredictions'
const selectedBucketStorageKey = 'selectedBucket'

export function Main() {
  const [metricsKeys, setMetricsKeys] = useState<MetricKey[]>([])
  const [selection, setSelection] = useState<Selection>()
  const [bucket, setBucket] = useState<BucketName>(() => {
    const selectedBucket = localStorage.getItem(selectedBucketStorageKey)
    return selectedBucket ? JSON.parse(selectedBucket) : 'oso-sounds'
  })

  function changeBucket(value: BucketName) {
    window.history.replaceState(null, '', '/')
    localStorage.setItem(selectedBucketStorageKey, JSON.stringify(value))
    setBucket(value)
  }

  useEffect(() => {
    async function updateMetricsNames() {
      if (!selection) return
      const { serial, date } = selection

      const [start, end] = calculateTimestampRange(date, selection.timeZone)
      const metricsNames = await getMetricsNames({ serial, start, end })

      setMetricsKeys(metricsNames.data)
    }

    updateMetricsNames()
  }, [selection])

  const [selectedMetricsKeys, setSelectedMetricsKeys] = useState(() => {
    const selectedPredictionsStorage = localStorage.getItem(
      selectedPredictionsStorageKey,
    )
    return selectedPredictionsStorage
      ? JSON.parse(selectedPredictionsStorage)
      : Object.keys(defaultMetrics)
  })

  const selectedMetricsConfig = useMemo<MetricsConfig>(() => {
    const defaultColors = Object.values(defaultMetrics).map(
      ({ color }) => color,
    )
    let index = 0
    const selectedMetricsConfig: MetricsConfig = {}
    for (const metricKey of selectedMetricsKeys) {
      if (defaultMetrics[metricKey] !== undefined) {
        selectedMetricsConfig[metricKey] = defaultMetrics[metricKey]
      } else {
        selectedMetricsConfig[metricKey] = {
          label: metricDisplayName(metricKey),
          color: defaultColors[index % defaultColors.length],
        }
        index += 1
      }
    }
    return selectedMetricsConfig
  }, [selectedMetricsKeys])

  return (
    <div className="flex flex-col gap-8">
      <BucketDialog bucket={bucket} handleValidate={changeBucket} />
      <S3Provider bucketName={bucket}>
        <div className="flex flex-row justify-around p-4 shadow-lg">
          {bucket === 'oso-sounds' ? (
            <Path setSelection={setSelection}></Path>
          ) : (
            <StandalonePath setSelection={setSelection}></StandalonePath>
          )}

          <DropdownCheckboxes
            options={metricsKeys}
            value={selectedMetricsKeys}
            onValidation={(selectedMetrics) => {
              setSelectedMetricsKeys(selectedMetrics)
              localStorage.setItem(
                selectedPredictionsStorageKey,
                JSON.stringify(selectedMetrics),
              )
            }}
            resetValue={Object.keys(defaultMetrics)}
            disabled={!selection}
          >
            Configurer
          </DropdownCheckboxes>
        </div>
        {selection && (
          <Explorer
            bucket={bucket}
            selection={selection}
            metricsConfig={selectedMetricsConfig}
          ></Explorer>
        )}
      </S3Provider>
    </div>
  )
}
