import { BucketName } from 'common/types'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { useCallback, useEffect, useRef, useState } from 'react'
import { DEFAULT_REGION } from 'shared/firebase/region'
import { Serial } from 'shared/types/utils'
import {
  GetPredictionsParams,
  GetPredictionsResponseData,
} from 'shared/types/victoria'
import { MetricsConfig } from 'shared/utils/web/metrics'
import { Prefix } from '../Explorer'
import { app } from '../firebase'

const functions = getFunctions(app, DEFAULT_REGION)
const getPredictions = httpsCallable<
  GetPredictionsParams,
  GetPredictionsResponseData
>(functions, 'getPredictions')

// Map from timestamp to array of predictions, one per requested metric
export type Predictions = Record<number, (number | null)[]>

type PrefixData = {
  start: number
  end: number
  predictions: Predictions
}

export const usePredictions = (
  metrics: MetricsConfig,
  serial: Serial,
  bucket: BucketName,
  bucketPrefix: string,
) => {
  const metricsRef = useRef<MetricsConfig>(metrics)
  const [predictions, setPredictions] = useState<Record<Prefix, PrefixData>>({})

  const forceLoadPrefixPredictions = useCallback(
    async (serial: string, prefix: Prefix, start: number, end: number) => {
      if (bucket !== 'oso-sounds') {
        setPredictions((predictions) => ({
          ...predictions,
          [prefix]: { start, end, predictions: [] },
        }))
        return
      }

      const result = await getPredictions({
        metrics: Object.keys(metrics),
        serial,
        start,
        end,
      })

      setPredictions((predictions) => ({
        ...predictions,
        [prefix]: { start, end, predictions: result.data },
      }))
    },
    [bucket, metrics],
  )

  const loadPrefixPredictions = useCallback(
    async (prefix: Prefix, start: number, end: number) => {
      if (predictions[prefix] === undefined)
        await forceLoadPrefixPredictions(serial, prefix, start, end)
    },
    [forceLoadPrefixPredictions, predictions, serial],
  )

  useEffect(() => {
    async function reloadPredictions() {
      for (const serial of Object.keys(predictions)) {
        const sortedPrefixes = Object.keys(predictions).toSorted()
        for (const prefix of sortedPrefixes) {
          const { start, end } = predictions[prefix]
          await forceLoadPrefixPredictions(serial, prefix, start, end)
        }
      }
    }

    if (metricsRef.current !== metrics) {
      reloadPredictions()
      metricsRef.current = metrics
    }
  }, [forceLoadPrefixPredictions, metrics, predictions])

  return {
    predictions: Object.entries(predictions).reduce<
      Record<Prefix, Predictions>
    >((acc, [prefix, prefixData]) => {
      if (prefix.startsWith(bucketPrefix))
        acc[prefix] = prefixData.predictions ?? {}
      return acc
    }, {}),
    loadPrefixPredictions,
  }
}
